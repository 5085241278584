<template>
    <section>
        <NavBar/>
        <AppMain />
        <FooterBar />
        <Sign/>
    </section>
</template>

<script>
import NavBar from './NavBar'
import AppMain from './AppMain'
import FooterBar from './FooterBar'
import Sign from './Sign'

export default {
    name: 'Layout',
    components: { NavBar, AppMain, FooterBar, Sign},
    created() {
        this.$store.dispatch('zongjie/getCompanyInfo')
    },
}
</script>

<style lang="less" scoped>
    @import "../style/index.less";

</style>