<template>
    <div id="app">
        <Layout />
    </div>
</template>

<script>
import Layout from './layout/index'

export default {
    name: 'app',
    components: { Layout }
}
</script>

<style>
#app{
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
p, h1, h2, h3, h4, h5, h6, ul{
    margin: 0;
}
.page{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.container{
    width: 100%;
    overflow-x:hidden;
    min-width: 1440px;
}
.content{
    max-width: 1200px;
}
.container .content{
    margin: 0 auto;
}
</style>