// import echarts from 'echarts/lib/echarts'
import { get } from '../http'


const state = {
    content:'',
    companyInfo: null
}

const mutations = {
    SET_CONTENT: (state, content) => {
        state.content = content
    },
    SET_COMPANY_INFO: (state, content) => {
        state.companyInfo = content
    },
}

const actions = {
    getModelContentByType({ commit },params) {
        return new Promise((resolve, reject) => {
            let _params=params;
            get('/api/model/modelContentByType/'+ _params).then(res => {
                let content = res.data;
                commit('SET_CONTENT', content.data.content) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getCompanyInfo({ commit }) {
        return new Promise((resolve, reject) => {
            get('/api/companyInfo').then(res => {
                let content = res.data;
                commit('SET_COMPANY_INFO', content.data) ; 
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
