<template>
    <el-dialog
    title="注册"
    top="20vh"
    :visible="signOnVisible"
    width="400px"
    @close="close"
    :close-on-click-modal="false"
    center>
        <el-form :model="signForm" status-icon :rules="rules" ref="signForm" >
            <el-form-item prop="role">
                <p class="label">您的身份<span class="dark" v-if="signForm.role < 9">（本人承诺所选身份为真实身份）</span></p>
                <div class="radio-wrap">
                    <el-radio v-model="signForm.role" label="1" border>投顾</el-radio>
                    <el-radio v-model="signForm.role" label="2" border>投资者</el-radio>
                </div>
                <p v-if="signForm.role == 1" class="tip">额外提供展业，客户投后管理工具</p>
                <p v-if="signForm.role == 2" class="tip">偏重基金投资DIY和智能组合</p>
            </el-form-item>
            <el-form-item prop="nickName">
                <el-input placeholder="请输入姓名" v-model.number="signForm.nickName" autocomplete="true" @input="handleInput">
                    <svg-icon slot="prefix" icon-class="register-name" />
                </el-input>
            </el-form-item>
            <el-form-item prop="tel">
                <el-input placeholder="请输入手机号" v-model.number="signForm.tel" autocomplete="true" @input="handleInput">
                    <svg-icon slot="prefix" icon-class="register-phone" />
                </el-input>
            </el-form-item>
            <el-form-item prop="companyName">
                <el-input placeholder="请输入机构名称" v-model.number="signForm.companyName" autocomplete="true" @input="handleInput">
                    <svg-icon slot="prefix" icon-class="register-company" />
                </el-input>
            </el-form-item>
            <el-form-item class="drag">
                <drag-verify
                    ref="dragVerify"
                    :width="320"
                    :height="32"
                    :isPassing.sync="isPassing"
                    text="请按住滑块拖动"
                    successText="验证通过"
                    @passcallback="pass"
                    handlerIcon="el-icon-d-arrow-right"
                    successIcon="el-icon-circle-check"
                    >
                </drag-verify>
            </el-form-item>
            <el-form-item v-if="identifyingCode" prop="identifying">
                <el-input style="width: 64%;" v-model="signForm.identifying">
                    <div slot="prefix" class="icon">
                        <img src="../assets/sign/validateIcon.png">
                    </div>
                </el-input>
                <el-button 
                style="margin-left: 12px" 
                type="primary" 
                ref="indentifyingBtn" 
                :disabled="indentifyingDisabled" 
                @click="getIndentifying">获取验证码</el-button>
                <p v-if="smsError" style="color: red;">{{ smsErrorMsg }}</p>
            </el-form-item>
            <el-form-item>
                <el-button style="width: 100%;" type="primary" @click="submitForm('signForm')" :disabled="signInDisabled">注册</el-button>
            </el-form-item>
        </el-form>
        <el-link class="footer" type='primary' href="https://amp.zhijuninvest.com" :underline="false">已有账号,去登入</el-link>
    </el-dialog>
</template>

<script>
import { newPost } from '@/http'
import dragVerify from "vue-drag-verify2";
export default {
    name: 'Sign',
    components: { 
        dragVerify
    },
    data() {
        let telValidator = (rule, value, callback)=>{
            if(/^1[3-9]\d{9}$/.test(value)) callback()
            else callback(new Error('请输入正确的手机号'))
        }
        let isExist = (rule, value, callback) => {
            newPost('/api/system/user/verifyAccount', {
                userName: value
            }).then(res=>{
                if(res.data.data == '0') callback()
                else callback(new Error('手机号已存在'))
            })
        }
        return {
            signForm: {
                role:'1',
                tel: '',
                identifying: '',
                nickName: '',
                companyName: ''
            },
            rules: {
                tel: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    {validator: telValidator, trigger: 'blur'},
                    {validator: isExist, trigger: 'blur'}
                ],
                nickName: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                companyName: [
                    {required: true, message: '请输入机构名称', trigger: 'blur'},
                ],
                identifying: [
                    {required: true, message: '请输入验证码', trigger: 'blur'},
                ]
            },
            //滑块成功与否
            isPassing: false,
            //是否显示验证码
            identifyingCode: false,
            //获取验证码按钮是否能点击
            indentifyingDisabled: false,
            timer: null,
            //验证码错误提示信息
            smsErrorMsg: '',
            smsError: false
        }
    },
    watch:{
        
    },
    computed: {
        signOnVisible() {
            return this.$store.state.sign.visible
        },
        signInDisabled() {
            return this.signForm.tel && this.signForm.nickName && this.signForm.compamyName
        }
    },
    mounted() {
        },
    methods: {
        unShow() {
            // this.timer && clearInterval(this.timer)
            this.$refs.signForm.resetFields()
            this.$store.commit('sign/setVisible', false)
            this.signForm = {
                role:'1',
                tel: '',
                identifying: '',
                nickName: '',
                companyName: ''
            }
        },
        handleInput(){
            this.isPassing = false
            this.identifyingCode = false
            this.$refs.dragVerify.reset()
        },
        //获取验证码
        getIndentifying() {
            newPost('/api/system/sms/sendSms', {
                phone: this.signForm.tel
            }).then((res)=>{
                if( res.code>=1001 && res.code <= 1003){
                    this.smsError = true
                    if(res.code == 1001) this.smsErrorMsg='发送失败'
                    else if(res.code == 1002) this.smsErrorMsg = '发送频繁'
                    else if(res.code == 1003) this.smsErrorMsg = '发送数量超出每天发送次数上限'
                    return
                }
                if(this.timer) return
                this.indentifyingDisabled = true;
                let str = 60
                let oldTime = Date.now()
                this.timer = setInterval(()=>{
                    let newTime = Date.now()
                    let dist = Math.floor((newTime - oldTime)/1000)
                    oldTime = newTime
                    str = str - dist
                    this.$refs.indentifyingBtn.$el.innerText = str+'s'
                    if(str <= 0 ){
                        this.$refs.indentifyingBtn.$el.innerText = '获取验证码'
                        this.indentifyingDisabled = false;
                        clearInterval(this.timer)
                        this.timer = null
                    }
                } ,1000)
            })
        },
        pass() {
            this.$refs.signForm.validateField('nickName')
            this.$refs.signForm.validateField('companyName')
            this.$refs.signForm.validateField('tel', error => {
                if(!error) {
                    //此处调用验证成功函数
                    this.identifyingCode = true
                    this.indentifyingDisabled = false
                }else{
                    this.isPassing = false
                    this.$refs.dragVerify.reset()
                }
            })
        },
        close() {
            this.unShow()
        },
        submitForm(formName){
            this.$refs[formName].validate(valid=>{
                if(valid) {
                    newPost('/api/system/user/register', {
                        userName: this.signForm.tel,
                        smsCode: this.signForm.identifying,
                        source: '1',
                        accountType: this.signForm.role,
                        nickName: this.signForm.nickName,
                        companyName: this.signForm.companyName
                    }).then((res)=>{
                        if(res.data.data == 200) {
                            this.$message.success(res.data.msg)
                            this.unShow()
                        }
                        else { 
                            this.smsError = true
                            this.smsErrorMsg = res.data.msg
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .icon{
        margin-top: 4px;
    }
    .drag{
        user-select: none;
    }
    .label{
        color: #999;
        line-height: 28px;
        text-align: left;
    }
    .label .dark{
        color: #333;
    }
    .slider{
        width: 100%;
        height: 32px;
        border: 1px solid #9e9e9e;
        background-color: #eee;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        position: relative;
        z-index: 100;
    }
    .slider-bg{
        height: 32px;
        position:absolute;
        top: 0;
        left: 0;
        background-color: #5EAE33;
        z-index: 200;
    }
    .slider-button{
        width: 30px;
        height: 32px;
        background-color: white;
        text-align: center;
        line-height: 32px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 300;
    }
    .footer{
        width: 100%;
        text-align: center;
    }
    /deep/ .drag_verify .dv_handler{
        border: 1px solid #CBCDD7;
        box-sizing:border-box;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    /deep/ .el-radio__input.is-checked+.el-radio__label, /deep/ .el-link.el-link--primary{
        color: #4E7CEE;
    }
    /deep/ .el-button--primary{
        background-color: #4E7CEE;
        border-color: #4E7CEE;
        height: 32px;
        line-height: 32px;
        padding: 0;
        border-radius: 2px;
    }
    .tip{
        line-height: 1;
        margin-top: 2px;
        font-size: 13px;
        color: #666;
    }
    .radio-wrap{
        display: flex;
        justify-content: space-between;
        /deep/ .el-radio{
            display: inline-block;
            width: 48%;
            height: 44px;
            text-align: center;
            line-height: 44px;
            padding: 0;
            margin: 0;
            border: 1px solid #999;
            color: #333;
            position: relative;
            overflow: hidden;
            &.is-checked{
                color: #4E7CEE;
                border-color: #4E7CEE;
                &::before{
                    content: '';
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    transform: rotate(45deg);
                    right: -17px;
                    bottom: -17px;
                    background: #4E7CEE;
                }
                &::after{
                    content: '\2713';
                    position: absolute;
                    right: 0;
                    bottom: -14px;
                    color: #fff;
                }
            }
            .el-radio__input{
                display: none;
            }
            .el-radio__label{
                padding-left: 0;
            }
        }
    }
    /deep/ .el-dialog--center .el-dialog__body{
        padding: 25px 40px 30px;
    }
    /deep/ .el-form-item{
        margin-bottom: 18px;
        .el-form-item__content{
            line-height: 32px;
        }
    }
    /deep/ .el-dialog__header{
        background:#4E7CEE;
        padding: 23px;
    }
    /deep/ .el-input__inner{
        line-height: 32px;
        height: 32px;
        border-radius: 2px;
    }
    /deep/ .el-input__prefix{
        line-height: 32px;
        font-size: 20px;
    }
    /deep/ .el-input__suffix, /deep/ .el-input__icon{
        line-height: 32px;
    }
    /deep/ .el-dialog__title{
        color: #fff;
        font-size: 18px;
        font-weight: 400;
    }
    /deep/ .el-dialog__close.el-icon.el-icon-close{
        // display: none;
        color: #FFFFFF;
    }
    /deep/ .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
        background: #F0F0F0;
        border-color:#F0F0F0;
        color: #CCCCCC;
    }

</style>