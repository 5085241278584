import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;'
    }
  });

instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    return config;
    }, (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
})
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export function newGet(url, data) {
    return instance.get(url, data)
}

export function newPost(url, data) {
    return instance.post(url, data)
}


//http://47.116.103.127:8088

axios.defaults.baseURL = process.env.VUE_APP_GW_API
// axios.defaults.withCredentials = true
axios.defaults.timeout = 50000

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8;'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;'

// export default axios

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}