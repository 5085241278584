<template>
    <section class="app-main">
        <router-view />
    </section>
</template>

<script>
export default {
    name: 'AppMain',
    data() {
        return{
            
        }
    }
}
</script>

<style scoped>

</style>
